<template>
  <div
    v-if="item?.children?.length"
    class="mm-breadcrumb-children"
    :class="{ disabled: item.disabledRoute }"
  >
    <base-tooltip
      interactive
      :placement="EPopperPlacement.Bottom"
      @close:popper="isOpenPopup = false"
    >
      <div
        class="breadcrumb-item"
        @click="isOpenPopup = true"
      >
        <span
          class="label"
          :class="{ active: isOpenPopup }"
        >
          {{ item?.label }}
        </span>

        <SvgIcon
          class="icon"
          :src="activeIcon"
        />
      </div>

      <template #content>
        <div class="breadcrumb-popup">
          <div
            v-for="(child, childIndex) in item.children"
            :key="childIndex"
            class="breadcrumb-popup__item"
          >
            <NuxtLink
              v-if="child.link"
              :to="manual ? undefined : child.link"
              class="label"
              :class="{ disabled: child.disabledRoute }"
              @click="onLinkItemClick(item)"
            >
              {{ child.label }}
            </NuxtLink>

            <div
              v-else
              class="label"
              @click="onChildItemClick({ item, child, childIndex })"
            >
              {{ child.label }}
            </div>
          </div>
        </div>
      </template>
    </base-tooltip>
  </div>

  <div
    v-else
    class="mm-breadcrumb"
    itemprop="itemListElement"
    itemscope
    itemtype="https://schema.org/ListItem"
  >
    <NuxtLink
      v-if="item.link"
      :to="manual ? undefined : item.link"
      class="label"
      :class="{ disabled: item.disabledRoute }"
      itemprop="item"
      @click="onLinkItemClick(item)"
    >
      <span itemprop="name">{{ item.label }}</span>
      <meta
        itemprop="position"
        :content="position"
      >
    </NuxtLink>

    <div v-else>
      <span class="label final">{{ item.label }}</span>

      <span
        v-if="item.subLabel"
        @click="onSubItemClick(item)"
      >
        <SvgIcon
          class="icon final-icon"
          src="action/pdf"
        />
        <span class="link">
          {{ item.subLabel }}
        </span>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IBreadcrumbsItem, IBreadcrumbChildItemClick } from '../../models/breadcrumbs.model';
import { EPopperPlacement } from '../../enums/popperPlacement.enum';

enum EIcons {
  ArrowUp = 'navigation/arrow-up',
  ArrowDown = 'navigation/arrow-down',
}

defineProps<{
  // Элемент хлебной крошки
  item: IBreadcrumbsItem;
  // Порядковый номер элемента в навигационной цепочке
  position: number;
  manual?: boolean;
}>();

const emit = defineEmits<{
  (e: 'childItemClick', value: IBreadcrumbChildItemClick): void;
  (e: 'subItemClick', value: IBreadcrumbsItem): void;
  (e: 'linkItemClick', value: IBreadcrumbsItem): void;
}>();

const isOpenPopup = ref<boolean>(false);
const activeIcon = computed<string>(() => (isOpenPopup.value ? EIcons.ArrowUp : EIcons.ArrowDown));

function onChildItemClick(item: IBreadcrumbChildItemClick): void {
  emit('childItemClick', item);
}

function onSubItemClick(item: IBreadcrumbsItem): void {
  emit('subItemClick', item);
}

function onLinkItemClick(item: IBreadcrumbsItem): void {
  emit('linkItemClick', item);
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/_variables';

.mm-breadcrumb-children {
  display: inline-flex;
  width: max-content;

  :deep(.mm-tooltip.mm-tooltip--base) {
    .popper {
      background: $filter-popup-bc;
      max-width: 269px;
      border-radius: 8px;
      padding: 4px 0;
      box-shadow: 0 2px 18px rgb(0 0 0 / 12%);
    }
  }

  .breadcrumb-item {
    border-right: 1px solid $dark-gray;
    padding-right: 8px;
    margin-right: 9px;
    cursor: pointer;

    .label {
      text-decoration: none;
      color: $text-light-green;
      font-size: 14px;
      line-height: 20px;

      &.active {
        color: $text-dark-green;
      }
    }

    .icon {
      margin-left: 10px;
    }
  }

  .breadcrumb-popup {
    &__item {
      margin: 16px;

      .label {
        font-size: 14px;
        line-height: 20px;
        color: $text-black;
        text-decoration: none;
        cursor: pointer;
      }

      .disabled {
        pointer-events: none;
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.mm-breadcrumb {

  .label {
    text-decoration: none;
    border-right: 1px solid $dark-gray;
    color: $text-light-green;
    font-size: 14px;
    line-height: 20px;
    padding-right: 8px;
    margin-right: 9px;
    user-select: none;
    cursor: pointer;
  }

  .final {
    border: none;
    width: 20px;
    margin-right: 4px;

    &:deep(path) {
      fill: $link;
    }
  }

  .final-icon {
    cursor: pointer;
    margin-right: 4px;

    &:deep(path) {
      fill: $link;
    }
  }

  .link {
    cursor: pointer;
    color: $link;
    font-size: 14px;
    line-height: 20px;
    padding-right: 8px;
    margin-right: 9px;
    user-select: none;
  }

  .disabled {
    cursor: default;
    pointer-events: none;
  }
}
</style>
